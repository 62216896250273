// react
import React from 'react';
import ReactDOM from 'react-dom';
import * as History from 'history';
import {
	Router,
	Route,
	Link,
	Switch,
	Redirect,
	useHistory,
	useLocation,
	useParams,
	useRouteMatch
} from 'react-router-dom';
import { Transition, CSSTransition, TransitionGroup } from 'react-transition-group';

// fundamental
import Styled, * as StyledFunctions from './common-3rd/styled-components-wrapper';

// utilities
import DateFNS from './common-3rd/date-fns';
import QRCodeGenerator from './common-3rd/qrcode-generator';
import ImageCompressor from './common-3rd/image-compressor';
import Exif from './common-3rd/exif-js';

// assistant
import VConsole from './common-3rd/vconsole';

export {
	Router,
	Route,
	Link,
	Switch,
	Redirect,
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
	History,
	React,
	ReactDOM,
	Transition,
	CSSTransition,
	TransitionGroup,
	Styled,
	StyledFunctions as StyledF,
	DateFNS,
	QRCodeGenerator,
	ImageCompressor,
	Exif,
	VConsole
};
