
/**
 * 业务工具类，只放与csms业务有关的静态工具
 */
class BusinessUtils {

    transformCoveragePeriodCHN(coveragePeriod: string) {
        if (!coveragePeriod) {
            return ''
        }
        if (coveragePeriod === 'WL') {
            return '保终身';
        }
        const prefix = coveragePeriod.substring(0, 1);
        const suffix = coveragePeriod.substring(1);
        if (prefix === 'Y') {
            return `保${suffix}年`;
        }
        if (prefix === 'A') {
            return `保至${suffix}周岁`;
        }
        if (prefix === 'M') {
            return `保${suffix}月`;
        }
        if (prefix === 'D') {
            return `保${suffix}天`;
        }
        return '无法识别';
    }

    transformInstallmentCHN(installment: string, paymentFreq: string) {
        if (!installment) {
            return ''
        }
        if (installment === 'S1' || (paymentFreq === '5' && installment === 'Y1')) {
            return '趸交';
        }
        const prefix = installment.substring(0, 1);
        const suffix = installment.substring(1);
        if (prefix === 'Y') {
            return `${suffix}年交`;
        }
        if (prefix === 'A') {
            return `交到${suffix}周岁`;
        }
        return '无法识别';
    }

    transformPaymentFreqCHN(paymentFreq: string) {
        return [{ v: '1', l: '年交' },
        { v: '2', l: '半年交' },
        { v: '3', l: '季交' },
        { v: '4', l: '月交' },
        { v: '5', l: '一次交清' }].find((item: any) => item.v == paymentFreq)?.l || '无法识别'
    }

}

export default new BusinessUtils();