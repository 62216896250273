/**
 * 引用第三方script链
 * 注意审查对方JS，防止XSS及其他安全问题
 */
class ScriptTools {
    static asyncLoadScript(url: string, id: string, callback: any) {
        let old_script = document.getElementById(id);
        if (old_script) {
            // @ts-ignore
            if (old_script.ready == true) {
                // console.log("INFO:already load:" + url);
                callback();
                return;
            } else {
                document.head.removeChild(old_script);
                // console.log("INFO:remove an old script that not ready:" + url);
            }
        }
        let script = document.createElement('script');
        script.id = id;
        script.src = url;
        script.type = 'text/javascript';
        // @ts-ignore
        script.onload = script!.onreadystatechange = function () {
            // @ts-ignore
            if (script.ready) {
                return false;
            }
            //这是FF的判断语句，因为ff下没有readyState这个值，IE的readyState肯定有值 
            // 这是IE的判断语句
            // @ts-ignore
            if (!script.readyState || script.readyState == "loaded" || script.readyState == 'complete') {
                // console.log("INFO:load:" + url);
                // @ts-ignore
                script.ready = true;
                callback();
            }
        };
        document.head.appendChild(script);
    }

    static asyncLoadScripts(scripts: any, id: string, callback: any) {
        var ok = 0;
        for (var i = 0; i < scripts.length; i++) {
            ScriptTools.asyncLoadScript(scripts[i], id, function () {
                ok++;
                if (ok == scripts.length) {
                    callback();
                }
            })
        }
    }

    static removeScripts(id: string): void {
        let old_script = document.getElementById(id);
        if (old_script) {
            document.head.removeChild(old_script);
        }
    }
}

export default ScriptTools;